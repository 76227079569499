import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/testing-react-components-enzyme",
  "date": "2016-08-22",
  "title": "TESTING REACT COMPONENTS WITH ENZYME",
  "author": "admin",
  "tags": ["development", "javascript", "es6"],
  "featuredImage": "feature.jpg",
  "excerpt": "React has become the framework of choice recently within the JavaScript community. And the tools for testing React components has also gained lot of attention."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Enzyme by Airbnb has been one of the popular choices for testing React components. In this article, we will look at how write tests with Enzyme and Mocha.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`We need Node.js installed to begin with. Follow the link, download `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/"
      }}>{`Node.js`}</a>{` for installation instructions.`}</p>
    <p>{`Now create a folder for your project and then run the following command in command line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm init
`}</code></pre>
    <h3>{`Babel`}</h3>
    <p>{`We need Babel to compile JSX code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install babel-cli ––save
npm install babel-preset-react babel-preset-es2015 ––save
`}</code></pre>
    <h3>{`Webpack`}</h3>
    <p>{`We are going to use Webpack to build our project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install webpack webpack-dev-server babel-loader ––save–dev
`}</code></pre>
    <h3>{`React`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install react react-dom ––save
`}</code></pre>
    <h3>{`Mocha`}</h3>
    <p>{`We need to install testing tools:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install chai enzyme mocha jsdom react-addons-test-utils ––save–dev
`}</code></pre>
    <h2>{`Setting Up`}</h2>
    <h3>{`Create `}<inlineCode parentName="h3">{`.babelrc`}</inlineCode></h3>
    <p>{`In order to create a preset to use Babel, we have to create a .babelrc file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": ["airbnb", "es2015", "stage-0"]
}
`}</code></pre>
    <p>{`We need to install the presets:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install babel-preset-stage-0 babel-preset-stage-airbnb ––save–dev
`}</code></pre>
    <h3>{`Create a `}<inlineCode parentName="h3">{`webpack.config.js`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var path = require('path');
var webpack = require('webpack');
 
// env
var buildDirectory = './dist/';
 
module.exports = {
  entry: './lib/main.jsx',
  devServer: {
    hot: true,
    inline: true,
    port: 7700,
    historyApiFallback: true,
  },
  resolve: {
    extensions: ['', '.js', '.jsx'],
  },
  output: {
    path: path.resolve(buildDirectory),
    filename: 'app.js',
    publicPath: 'http://localhost:7700/dist',
  },
  externals: {
    'cheerio': 'window',
    'react/lib/ExecutionEnvironment': true,
    'react/lib/ReactContext': true,
  },
  module: {
    loaders: [{
      test: /\\.jsx?$/,
      exclude: /(node_modules|bower_components)/,
      loader: 'babel',
      query: {
        presets: ['react', 'es2015', 'stage-0'],
      },
    }],
  },
  plugins: [],
};
`}</code></pre>
    <p>{`The configuration for `}<inlineCode parentName="p">{`externals`}</inlineCode>{` will help enable enzyme to work properly.`}</p>
    <h3>{`Create browser.js`}</h3>
    <p>{`We create a setup file in order to provide a realistic browser environement using `}<inlineCode parentName="p">{`jsdom`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require('babel-register')();
 
var jsdom = require('jsdom').jsdom;
 
var exposedProperties = ['window', 'navigator', 'document'];
 
global.document = jsdom('');
global.window = document.defaultView;
Object.keys(document.defaultView).forEach((property) => {
  if (typeof global[property] === 'undefined') {
    exposedProperties.push(property);
    global[property] = document.defaultView[property];
  }
});
 
global.navigator = {
  userAgent: 'node.js'
};
 
documentRef = document;
`}</code></pre>
    <h3>{`The package.json Scripts`}</h3>
    <p>{`Add the following to the scripts sections of the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json\"scripts\":",
        "metastring": "{",
        "{": true
      }}>{`   "test": "mocha -w test/helpers/browser.js test/*.spec.js",
   "dev:hot": "webpack-dev-server --hot --inline --progress --colors --watch --display-error-details --display-cached --content-base ./"
 },
`}</code></pre>
    <h2>{`Creating Components`}</h2>
    <p>{`Create a `}<inlineCode parentName="p">{`containers`}</inlineCode>{` folder and a `}<inlineCode parentName="p">{`LoginForm.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import FormField from '../components/FormField';
 
class LoginForm extends React.Component {
  render() {
    return (
      <form>
        <FormField model="user.username" label="Username" type="text" />
        <FormField model="user.password" label="Password" type="password"/>
        <button>Log in</button>
      </form>
    );
  }
}
 
export default LoginForm;
`}</code></pre>
    <p>{`Create a `}<inlineCode parentName="p">{`components`}</inlineCode>{` folder and a `}<inlineCode parentName="p">{`FormField.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
 
const FormField = ({ label, type }) => {
  return (
    <field>
      <label>{label}</label>
      <input type={type} />
    </field>
  );
};
 
export default FormField;
`}</code></pre>
    <h2>{`Testing Components.`}</h2>
    <p>{`Create a `}<inlineCode parentName="p">{`test`}</inlineCode>{` folder and create a `}<inlineCode parentName="p">{`LoginForm.spec.js`}</inlineCode>{` file in it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { mount, shallow } from 'enzyme';
import { expect } from 'chai';
 
import LoginForm from '../containers/LoginForm';
import FormField from '../components/FormField';
 
describe('<LoginForm/>', function () {
  it('should have a button to submit the form', function () {
    const wrapper = shallow(<LoginForm/>); // highlight
    expect(wrapper.find('button')).to.have.length(1);
  });
 
  it('should have props for model, label and type', function () {
    const wrapper = shallow(<LoginForm/>);
    expect(wrapper.props().model).to.be.defined;
    expect(wrapper.props().label).to.be.defined;
    expect(wrapper.props().type).to.be.defined;
  });
 
  it('contains an <FormField/> component', function () {
    const wrapper = mount(<FormField/>);
    expect(wrapper.find(FormField)).to.have.length(1);
  });
});
`}</code></pre>
    <p>{`Not the highlighted line: The `}<inlineCode parentName="p">{`shallow`}</inlineCode>{` method from enzyme will allow us to “shallowly” render a component.
This type of rendering is used to isolate one component for testing and ensure child components do not affect assertions.`}</p>
    <Message type="info" title="mount" content="The `mount` method helps *real* rendering that will actually render your component into a browser environment. If you are creating full React components (and not just stateless components), you will want to use mount to do testing on the lifecycle methods of your component." mdxType="Message" />
    <p>{`Now run the tests in the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm test
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`We have worked through creating a simple React component, installing test tools and running actual tests. I hope this tutorial will help you to kick start testing with React.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      